// Declarar las variables una sola vez
const menuBtn = document.getElementById('menu-btn');
const mobileMenu = document.getElementById('mobile-menu');
const menuIcon = document.getElementById('menu-icon');
const body = document.body;
const menuLinks = document.querySelectorAll('nav a'); // Seleccionar todos los enlaces del menú (móvil y desktop)
let isMenuOpen = false; // Estado del menú (abierto/cerrado)

// Mostrar/ocultar el menú móvil al hacer clic en el botón del menú
menuBtn.addEventListener('click', () => {
  mobileMenu.classList.toggle('hidden'); // Mostrar/ocultar el menú móvil
  body.classList.toggle('overflow-hidden'); // Evitar/habilitar el scroll al abrir el menú
  isMenuOpen = !isMenuOpen; // Alternar estado del menú

  // Cambiar el ícono del botón hamburguesa
  if (mobileMenu.classList.contains('hidden')) {
    menuIcon.innerHTML = `<path d="M4 6h16M4 12h16m-7 6h7"></path>`; // Ícono de hamburguesa
  } else {
    menuIcon.innerHTML = `<path d="M6 18L18 6M6 6l12 12"></path>`; // Ícono de cerrar (X)
  }
});

// Ocultar el menú móvil al hacer clic en cualquier enlace del menú
menuLinks.forEach(link => {
  link.addEventListener('click', function() {
    mobileMenu.classList.add('hidden');
    body.classList.remove('overflow-hidden'); // Habilitar el scroll
    isMenuOpen = false; // Marcar el menú como cerrado
    menuIcon.innerHTML = `<path d="M4 6h16M4 12h16m-7 6h7"></path>`; // Restaurar el ícono de hamburguesa
  });
});

// Manejar el scroll para ocultar o mostrar el TopBar y Header
let lastScrollTop = 0;
const topbar = document.getElementById('topbar');
const header = document.getElementById('header');

window.addEventListener('scroll', function() {
  const currentScroll = window.scrollY || document.documentElement.scrollTop;

  if (currentScroll > lastScrollTop) {
    // Scrolling down
    topbar.style.transform = 'translateY(-100%)';
    header.style.transform = 'translateY(-50%)';
    if (isMenuOpen) {
      mobileMenu.style.transform = 'translateY(-100%)';
    }
  } else {
    // Scrolling up
    topbar.style.transform = 'translateY(0)';
    header.style.transform = 'translateY(0)';
    if (isMenuOpen) {
      mobileMenu.style.transform = 'translateY(0)';
    }
  }

  lastScrollTop = currentScroll <= 0 ? 0 : currentScroll;
});




const cta = document.getElementById("cta");

const observer = new IntersectionObserver((entries) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      cta.classList.remove("visible");
    } else {
      cta.classList.add("visible");
    }
  });
}, { threshold: 0 });

observer.observe(document.querySelector("#ubicacion"));
observer.observe(document.querySelector("footer"));
observer.observe(document.getElementById("wp-btn-desk"));
observer.observe(document.querySelector("#end-of-page"));







// Función para verificar qué sección está en vista y activar el enlace correspondiente
function setActiveLink() {
  let index = -1;
  
  // Iterar sobre cada sección para verificar cuál está en vista
  document.querySelectorAll('section').forEach((section, i) => {
    const rect = section.getBoundingClientRect();
    if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
      index = i; // Identificar la sección visible
    }
  });

  // Remover la clase 'activo' de todos los enlaces
  menuLinks.forEach(link => link.classList.remove('activo'));

  // Agregar la clase 'activo' al enlace correspondiente si se encontró la sección
  if (index !== -1) {
    menuLinks.forEach(link => {
      // Comparar el href del enlace con el id de la sección visible
      if (link.getAttribute('href') === `#${document.querySelectorAll('section')[index].id}`) {
        link.classList.add('activo');
      }
    });
  }
}

// Llamar a la función cuando el usuario hace scroll
window.addEventListener('scroll', setActiveLink);

// También llamar a la función cuando la página se carga para establecer el enlace activo inicialmente
window.addEventListener('load', setActiveLink);




function createIframe() {
  return `
    <iframe 
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.517616371986!2d-99.04210952402956!3d19.39002894203776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1ff87e220f72f%3A0x205133a14671241b!2sGGB%20Servicios%20TI%2B!5e0!3m2!1sen!2smx!4v1725665480714!5m2!1sen!2smx"
      width="100%" 
      height="300" 
      style="border:5px solid #84cc16; border-radius: 0.5rem;" 
      allowfullscreen="" 
      loading="lazy"
      title="Mapa de ubicación de GGB Servicios TI+">
    </iframe>`;
}

document.addEventListener('DOMContentLoaded', function() {
  // Insertar iframe en la vista de escritorio
  const mapContainer = document.getElementById('map-container');
  if (mapContainer) {
    mapContainer.innerHTML = createIframe();
    console.log("Iframe inserted successfully in desktop view");
  } else {
    console.log("Map container not found in desktop view");
  }

  // Insertar iframe en la vista móvil
  const mapContainerMobile = document.getElementById('map-container-mobile');
  if (mapContainerMobile) {
    mapContainerMobile.innerHTML = createIframe();
    console.log("Iframe inserted successfully in mobile view");
  } else {
    console.log("Map container mobile not found");
  }
});






document.addEventListener('DOMContentLoaded', () => {
  const modalPrivacidad = document.getElementById('modal-privacidad');
  const modalTerminos = document.getElementById('modal-terminos');

  // Enlaces para abrir los modales
  const linkPrivacidadMobile = document.getElementById('open-privacidad-mobile');
  const linkTerminosMobile = document.getElementById('open-terminos-mobile');
  const linkPrivacidadDesktop = document.getElementById('open-privacidad-desktop');
  const linkTerminosDesktop = document.getElementById('open-terminos-desktop');

  // Función para cargar contenido en el modal
  async function cargarContenidoModal(modal, url) {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Error de red');
      const text = await response.text();
      modal.querySelector('.modal-body').innerHTML = text;
    } catch (error) {
      console.error('Error al cargar el contenido del modal:', error);
      modal.querySelector('.modal-body').innerHTML = '<p>Error al cargar el contenido.</p>';
    }
  }

  // Mostrar el modal
  function mostrarModal(modal, url) {
    cargarContenidoModal(modal, url);
    modal.classList.remove('hidden');
    document.body.style.overflow = 'hidden'; // Desactivar el scroll de fondo
  }

  // Cerrar el modal
  function cerrarModal(event) {
    const modalId = event.currentTarget.getAttribute('data-close');
    const modal = document.getElementById(modalId);
    modal.classList.add('hidden');
    document.body.style.overflow = ''; // Reactivar el scroll de fondo
  }

  // Añadir eventos a los enlaces
  linkPrivacidadMobile?.addEventListener('click', (e) => {
    e.preventDefault();
    mostrarModal(modalPrivacidad, 'aviso-privacidad.html');
  });

  linkTerminosMobile?.addEventListener('click', (e) => {
    e.preventDefault();
    mostrarModal(modalTerminos, 'terminos-condiciones.html');
  });

  linkPrivacidadDesktop?.addEventListener('click', (e) => {
    e.preventDefault();
    mostrarModal(modalPrivacidad, 'aviso-privacidad.html');
  });

  linkTerminosDesktop?.addEventListener('click', (e) => {
    e.preventDefault();
    mostrarModal(modalTerminos, 'terminos-condiciones.html');
  });

  // Cerrar el modal con el botón
  document.querySelector('button[data-close="modal-privacidad"]')?.addEventListener('click', cerrarModal);
  document.querySelector('button[data-close="modal-terminos"]')?.addEventListener('click', cerrarModal);

  // Cerrar el modal si se hace clic fuera del contenido
  document.querySelectorAll('.modal').forEach(modal => {
    modal.addEventListener('click', (e) => {
      if (e.target === modal) {
        cerrarModal(e);
      }
    });
  });
});





























